import { Currency, CurrencyCode } from "@/types";

export const convertAmount = (amount: number, currencyTo: Currency, currencyFrom?: Currency): string => {
  if (amount === 0) {
    return "0.00";
  }

  let formattedAmount: string;

  if (!currencyTo || currencyTo.code === CurrencyCode.USD) {
    formattedAmount = amount.toFixed(2);
  } else {
    let convertedAmount: number;

    if (currencyFrom) {
      const convertedToUSD = amount / currencyFrom.exchangeRate;
      convertedAmount = convertedToUSD * currencyTo.exchangeRate;
    } else {
      const exchangeRate = currencyTo.exchangeRate;
    
      if (!exchangeRate) {
        console.warn(`Exchange rate not found for ${currencyTo.code}`);
        return amount.toFixed(2); 
      }

      convertedAmount = amount * exchangeRate;
    }

    formattedAmount = convertedAmount.toFixed(2);
  }

  return formattedAmount;
};


export const convertAmountNumber = (
  amount: number,
  currencyTo: Currency,
  currencyFrom?: Currency
): number => {
  if (amount === 0) {
    return 0.0;
  }

  let convertedAmount: number;

  if (!currencyTo || currencyTo.code === CurrencyCode.USD) {
    convertedAmount = roundToTwoDecimals(amount);
  } else {
    if (currencyFrom) {
      const convertedToUSD = amount / currencyFrom.exchangeRate;
      convertedAmount = convertedToUSD * currencyTo.exchangeRate;
    } else {
      const exchangeRate = currencyTo.exchangeRate;

      if (!exchangeRate) {
        console.warn(`Exchange rate not found for ${currencyTo.code}`);
        return roundToTwoDecimals(amount);
      }

      convertedAmount = amount * exchangeRate;
    }

    convertedAmount = roundToTwoDecimals(convertedAmount);
  }

  return convertedAmount;
};

const roundToTwoDecimals = (value: number): number => {
  return Math.round(value * 100) / 100;
};