'use client'
import { useTranslations } from "next-intl";
import React from 'react';
import { convertAmount } from "@/utils/convertAmount";
import { useCurrency } from "@/app/[locale]/_providers/CurrencyContext";
import { VehicleClassWithDetails } from "@/utils/prisma/getVehicleClassesWithPriceHistory";

interface Props {
  vehicle: VehicleClassWithDetails;
}

export default function VehiclePrice({ vehicle }: Props) {
  const { currency } = useCurrency();
  const t = useTranslations('VehiclePicker.VehicleCard');

  const basePriceInUSD = vehicle.PriceHistory[0].basePrice;
  const convertedAmount = convertAmount(Number(basePriceInUSD), currency!);

  return (
    <h2 className="text-start text-xl font-lota">
      {currency?.symbol}{convertedAmount}<span className="text-slate-400 font-lota">/{t('day')}</span>
    </h2>
  );
}
