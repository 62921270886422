'use client'

import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { cn } from "@/lib/utils"
import { DialogClose, DialogDescription } from "@radix-ui/react-dialog"
import { format, addDays, isAfter, isBefore } from 'date-fns'
import { DateDisplay } from "@/components/booking-form/DatesDisplay"
import { useQueryState, parseAsIsoDate } from 'nuqs'
import { DateRange } from 'react-day-picker'
import { useTranslations } from "next-intl"
import { useDateRange } from "@/hooks/useDateRange"
import { CalendarImproved } from "../ui/calendar-improved"


interface MobileCalendarProps {
  isSelectDates?: boolean;
  nuqsShallow: boolean;
}

export default function MobileCalendar({ isSelectDates = false, nuqsShallow }: MobileCalendarProps) {
  const t = useTranslations('Home.BookingForm')
  const { dateRange, setDateRange } = useDateRange(nuqsShallow);
  
  return (

    <Dialog>
      <DialogTrigger asChild>
        {isSelectDates ? <Button
          variant="link"
          className="text-purple font-semibold hover:text-primary transition-colors p-0"
        >
          {t('pickMyDates')}
        </Button> : <Button
          variant={"ghost"}
          className="w-full border-none p-0 pl-1 h-[30px] justify-start text-left font-normal text-slate-500 hover:text-accent-foreground hover:bg-transparent"
        >
          <span className="text-xl sm:text-2xl font-normal">
            <DateDisplay from={dateRange.from} to={dateRange.to} />
          </span>
        </Button>}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('pickMyDates')}</DialogTitle>
          <DialogDescription className="hidden">{t('pleaseSelectADateRange')}</DialogDescription>
        </DialogHeader>
        <CalendarImproved
          mode="range"
          selected={dateRange}
          onSelect={setDateRange}
          numberOfMonths={1}
          disabled={(date) =>
            date <= new Date(new Date().setHours(0, 0, 0, 0))
          }
          defaultMonth={dateRange.from || new Date()}
        />
        <div className="text-center w-full">
          <DateDisplay from={dateRange.from} to={dateRange.to} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button className="w-full">
              {t('confirmDates')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}