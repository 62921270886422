import { Message } from "@/components/FormMessage";
import { Role, Tenant, Status, HqLocation, VehicleClass, VehicleModel} from "@prisma/client";

export interface SearchParams {
  google_maps_address: string;
  location_id: string;
  pick_up_date: string;
  return_date: string;
  pick_up_time: string;
  return_time: string;
  vehicle_class_id: number;
  vehicle_name: string;
  vehicle_model: string;
  daily_price: string;
  total_days: number;
  total_price: string;
  total_price_usd: string;
  total_price_with_taxes: string;
  tenant_uuid: string;
  tenant_logo: string;
  tenant_name: string;
  rating: string;
  number_of_reviews: string;
  vehicle_image: string;
  vehicle_lezgo_id: string;
  coordinates: string;
  error: string;
  success: string;
  message: string;
}

export interface ApiData {
  google_maps_address: string;
  pick_up_location: string;
  return_location: string;
  pick_up_date: string;
  return_date: string;
  pick_up_time: string;
  return_time: string;
  vehicle_class_id: number;
  contact_entity: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_email: string;
  customer_phone_number: string; 
  total_price: number;
  ota_reservation_type: string;
  tenant_uuid: string;
  additional_charges: string[];
  includes_selected_by_default_additional_charges: string;
  vehicle_image: string;
  vehicle_lezgo_id?: string;
  hq_location_id: string;
}

export type LatLong = {
  type?: string,
  coordinates: number[]
}

// AdditionalCharge interface

export interface LabelCategory {
  en: string;
  es: string;
}

export interface AdditionalChargePrice {
  currency: string;
  currency_icon: string;
  amount: string;
  usd_amount: string;
  amount_for_display: string;
}

export interface AdditionalChargeCategory {
  id: number;
  label: LabelCategory;
}

export interface AdditionalCharge {
  id: number;
  additional_charge_category: AdditionalChargeCategory;
  label: string;
  charge_type: string;
  selection_type: string;
  is_selected: boolean;
  mandatory: boolean;
  recommended: boolean;
  short_description: string;
  base_price: AdditionalChargePrice;
  base_price_with_taxes: AdditionalChargePrice;
  total_price: AdditionalChargePrice;
  total_price_with_taxes: AdditionalChargePrice;
  quantity: number;
  selected_quantity?: number;
}

export interface SecurityDeposit {
  id: number;
  available: boolean;
  with_brand: boolean;
  brand_id: number;
  with_additional_charge: boolean;
  with_vehicle_class: boolean;
  amount: string;
  currency: string | null;
  additional_charges: (number | null)[] | null;
  vehicle_classes: (number | null)[] | null;
  applicable_excess: Amount| null;
  tenant_uuid: string | null;
}
export type Amount = {
  amount: string;
  amount_for_display: string;
  currency: string;
  currency_icon: string;
  usd_amount: string;
};

export interface SecurityDepositMapping {
  tenant_uuid: string;
  vehicle_class_id: number;
  deposit_amount: string;
  deposit_currency: string | null;
}

export interface CalculationResult {
  extrasTotalPrice: number;
  extrasTotalPriceWithTaxes: number;
  extrasTaxes: number;
  totalTaxes: number;
  totalTripPriceWithTaxes: number;
}

export interface ExtrasContextType {
  extras: AdditionalCharge[];
  setExtras: React.Dispatch<React.SetStateAction<AdditionalCharge[]>>;
  mandatoryExtras: AdditionalCharge[];
  setMandatoryExtras: React.Dispatch<React.SetStateAction<AdditionalCharge[]>>;
  selectedExtras: AdditionalCharge[];
  setSelectedExtras: React.Dispatch<React.SetStateAction<AdditionalCharge[]>>;
  insurances: AdditionalCharge[];
  setInsurances: React.Dispatch<React.SetStateAction<AdditionalCharge[]>>;
  sortedInsurances: AdditionalCharge[];
  selectedInsurances: AdditionalCharge[];
  setSelectedInsurances: React.Dispatch<React.SetStateAction<AdditionalCharge[]>>;
  defaultInsurances: AdditionalCharge[];
  setDefaultInsurances: React.Dispatch<React.SetStateAction<AdditionalCharge[]>>;
  resetExtras: () => void;
}

export interface SecurityDepositsContextType {
  securityDeposits: SecurityDeposit[];
  securityDepositMapping: SecurityDepositMapping[];
  setSecurityDeposits: React.Dispatch<React.SetStateAction<SecurityDeposit[]>>;
  setSecurityDepositMapping: React.Dispatch<React.SetStateAction<SecurityDepositMapping[]>>;
  addSecurityDepositMapping: (mapping: SecurityDepositMapping) => void;
}

export interface  TenantsContextType {
  tenants: Tenant[];
  setTenants: React.Dispatch<React.SetStateAction<Tenant[]>>;
  closeTenants: Tenant[];
  setCloseTenants: React.Dispatch<React.SetStateAction<Tenant[]>>;
}

export interface  LocationContextType {
  location?: HqLocation;
  setLocation: React.Dispatch<React.SetStateAction<HqLocation | undefined>>;
}


export interface  SortingContextType {
  sortingMethod: SortingMethod;
  setSortingMethod: React.Dispatch<React.SetStateAction<SortingMethod>>;
}


export interface FilterOption {
  id: string;
  label: string;
  count: number;
}

export interface FilterSection {
  title: string;
  id: string;
  options: FilterOption[];
}

export interface Feature {
  id: FeatureID;
  order: number;
  label: string;
}

export interface Price {
  base_price: {
    amount: string;
    usd_amount: string;
  },
  base_price_with_taxes: {
    amount: string;
    usd_amount: string;
  },
  currency: string;
  lezgo_price: number;
  total_days: number;
}

export interface Vehicle {
  vehicle_class_id: number;
  location_id: string;
  rating: number;
  number_of_reviews: number;
  sipp_code: string;
  vehicle_class: VehicleClass;
  vehicle_model: VehicleModel;
  price: Price;
  features: Feature[];
  tenant: Tenant;
  distance_limits: {
    distance_unlimited: boolean;
  };
}

export type PriceBuckets = {
  BUCKET_1: { min: number; max: number };
  BUCKET_2: { min: number; max: number };
  BUCKET_3: { min: number; max: number };
  BUCKET_4: { min: number; max: number };
};

export type DepositBuckets = {
  BUCKET_1: { min: number; max: number };
  BUCKET_2: { min: number; max: number };
  BUCKET_3: { min: number; max: number };
};

export type FeatureID = "manual" | "automatic" | "air-conditioning" | "4+-doors";

export type PricePerDayFilterID = "BUCKET_1" | "BUCKET_2" | "BUCKET_3" | "BUCKET_4";

export type CarSpecsFilterID = "manual" | "automatic" | "air-conditioning" | "4+-doors";

export type MileageFilterID = "unlimited";

export type CarCategoryFilterID = "small" | "medium" | "large" | "premium" | "minivan" | "suv";

export type CarDepositFilterID = "BUCKET_1" | "BUCKET_2" | "BUCKET_3";

export type CarSupplierFilterID = "alamo" | "avis" | "budget" | "dollar" | "europcar" | "national" | "sixt" | "thrifty";

export type SortingMethod = "top-reviewed" | "ascending" | "descending";

export interface SelectedFilters {
  [key: string]: string[];
}

declare global {
  interface Window {
    handleSignInWithGoogle: (response: any) => void;
  }
}

export type City = "curacao" | "miami" | "default";

export interface Adventure {
  id: number;
  city: City;
  title: string;
  description: string;
  image: string;
  linkText: string;
  linkUrl: string;
}

export enum CurrencyCode {
  USD = "USD",
  EUR = "EUR",
  ANG = "ANG",
  CAD = "CAD",
}

export type Currency = {
  name: string;
  code: CurrencyCode;
  symbol: string;
  exchangeRate: number;
};

export interface I_User {
	id: string;
	email: string;
	phone: string;
	password: string;
	pin: string;
	firstName: string;
	lastName: string;
	avatar: string;
	role: Role;
	status: Status;
	totpSecret: string;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date;
	lastLogin: Date;
	lastSeen: Date;
}

export interface I_UserPublic extends Omit<I_User, 'password' | 'totpSecret'> {}

export interface AuthPayload {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  avatarUrl: string;
  tenantId: string;
  iat: number;
  exp: number;
  openIdSub?: string;
}


