'use client'

import { useCallback } from "react"
import { Button } from "@/components/ui/button"
import { useTranslations } from "next-intl"
import MobileCalendar from "@/components/booking-form/MobileCalendar"


export default function SelectDatesBanner() {
  const t = useTranslations('Home.VehicleGrid')
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className="bg-sky-50 px-4 py-2 flex items-center gap-2 w-full sm:w-fit">
      <span className="text-slate-500 font-medium">$</span>
      <span className="text-slate-500 font-medium">
        {t('getTheBestDeals')}
      </span>
      <MobileCalendar isSelectDates={true} nuqsShallow={false} />
    </div>
  )
}