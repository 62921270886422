'use client';

import { useEditBooking } from "@/app/[locale]/_providers/EditBookingContext";
import { usePlace } from "@/app/[locale]/_providers/PlaceContext";
import AutocompleteSearchBar from "@/components/booking-form/AutocompleteSearchBar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useRouter } from "@/i18n/routing";
import { City } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { addDays, isSameDay, parse } from "date-fns";
import { useTranslations } from "next-intl";
import Image from 'next/image';
import { useEffect, useState, useTransition } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import LezgoButton from "./LezgoButton";
import LezgoCalendar from "./LezgoCalendar";
import { useDateRange } from "@/hooks/useDateRange";
import { formatInTimeZone } from "date-fns-tz";


interface Props {
  city?: City;
  isVariant?: boolean;
  google_maps_address?: string;
  pick_up_date?: string;
  return_date?: string;
  pick_up_time?: string;
  return_time?: string;
  latitude?: number;
  longitude?: number;
  nuqsShallow?: boolean;
}

export default function BookingForm({
  city,
  isVariant = false,
  google_maps_address,
  pick_up_date,
  return_date,
  pick_up_time,
  return_time,
  latitude,
  longitude,
  nuqsShallow = false
}: Props) {
  const t = useTranslations('Home.BookingForm');
  const router = useRouter();
  const { place, setPlace } = usePlace();
  const [isPlaceValid, setIsPlaceValid] = useState(false);
  const { isEditingBooking, closeEditBooking } = useEditBooking();
  const [dateError, setDateError] = useState<string | null>(null);
  const { fromDate, toDate, setDateRange } = useDateRange();
  const now = new Date();
  const threeDaysFromNow = addDays(now, 3);
  const sixDaysFromNow = addDays(now, 6);

  const BookingFormSchema = z.object({
    location: z
      .string()
      .min(2, t("errors.location.min", { count: 2 }))
      .max(100, t("errors.location.max", { count: 100 })),

    dates: z
      .object({
        from: z.date().optional(),
        to: z.date().optional(),
      })
      // Ensure both 'from' and 'to' dates are provided
      .refine(
        (data) => data.from && data.to,
        {
          message: t("errors.dates.required"),
        }
      )
      // Ensure 'to' date is not the same as 'from' date
      .refine(
        (data) => {
          if (data.from && data.to) {
            return !isSameDay(data.from, data.to);
          }
          return true; // If one is missing, skip this validation
        },
        {
          message: t("errors.dates.sameDay"),
        }
      ),

    pickUpTime: z
      .string()
      .min(1, t("errors.pickUpTime.required"))
      .max(24, t("errors.pickUpTime.max")),

    returnTime: z
      .string()
      .min(1, t("errors.returnTime.required"))
      .max(24, t("errors.returnTime.max")),
  });

  const form = useForm<z.infer<typeof BookingFormSchema>>({
    resolver: zodResolver(BookingFormSchema),
    defaultValues: {
      location: google_maps_address || "",
      dates: {
        from: fromDate || undefined,
        to: toDate || undefined,
      },
      pickUpTime: pick_up_time || "11:00",
      returnTime: return_time || "11:00",
    },
  });

  useEffect(() => {
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setIsPlaceValid(!!lat && !!lng);
    } else {
      setIsPlaceValid(false);
    }
  }, [place]);

  useEffect(() => {
    if (google_maps_address && latitude && longitude) {
      const newPlace = {
        name: google_maps_address,
        geometry: {
          location: { lat: () => latitude, lng: () => longitude }
        },
        formatted_address: google_maps_address
      } as google.maps.places.PlaceResult;
      setPlace(newPlace);
      setIsPlaceValid(true);
      form.setValue("location", google_maps_address);
    }
  }, [google_maps_address, latitude, longitude, setPlace, form]);

  useEffect(() => {
    const subscription = form.watch((values) => {
      if (values.location === "") {
        setIsPlaceValid(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  async function onSubmit(values: z.infer<typeof BookingFormSchema>) {
    if (!isPlaceValid) {
      form.setError("location", {
        type: "manual",
        message: "Please select a valid location from the suggestions."
      });
      return;
    }

    let pick_up_date: Date | null;
    let return_date: Date | null;

    // Check if both dates are set via the hook
    if (!fromDate || !toDate) {
      pick_up_date = threeDaysFromNow;
      return_date = sixDaysFromNow;
    } else {
      // Proceed with submission using the hook's dates
      pick_up_date = fromDate;
      return_date = toDate;
    }

    const formattedPickUpDate = formatInTimeZone(pick_up_date, 'UTC', 'yyyy-MM-dd');
    const formattedReturnDate = formatInTimeZone(return_date, 'UTC', 'yyyy-MM-dd');

    const url = new URL("/car-picker", window.location.origin);
    url.searchParams.set("google_maps_address", place?.name || '');
    url.searchParams.set("pick_up_date", formattedPickUpDate);
    url.searchParams.set("return_date", formattedReturnDate);
    url.searchParams.set("pick_up_time", values.pickUpTime);
    url.searchParams.set("return_time", values.returnTime);
    url.searchParams.set("max_distance", "5");

    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      if (lat && lng) {
        const coordinates = `${lat}%2C${lng}`;
        url.searchParams.set("coordinates", coordinates);
      }
    }

    router.push(url.href);
    closeEditBooking();
  }

  return (
    <div className={`w-full ${isVariant ? 'z-10 bg-white' : `${isEditingBooking ? 'sm:mt-2' : 'max-w-[600px]'} z-20 sm:mb-4 bg-white border border-slate-100`}`}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <div>
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 border-b-2 border-slate-100 p-3">
                  <FormLabel className="text-sm tracking-wide text-slate-500 font-semibold">
                    {t('whereTo').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <div className="relative">
                      <AutocompleteSearchBar onChange={(value) => {
                        field.onChange(value);
                        form.clearErrors("location");
                      }} city={city} initialValue={google_maps_address} />
                      <Image
                        src="/images/lezgo/lezgo-blocks.svg"
                        alt="Blocks"
                        width={40}
                        height={40}
                        className={`absolute right-[-20px] bottom-[33px] ${isEditingBooking ? 'sm:right-[-20px] sm:bottom-[-40px]' : "sm:right-[-40px] sm:bottom-[-40px]"} mb-2 mr-2`}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <LezgoCalendar nuqsShallow={nuqsShallow} dateError={dateError} />

          <div className={`grid ${isVariant ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-2'}`}>
            <FormField
              control={form.control}
              name="pickUpTime"
              render={({ field }) => (
                <FormItem className="flex space-y-0 flex-col gap-1 border-b-2 border-r-2 border-slate-100 p-3">
                  <FormLabel className="text-sm text-slate-500 font-semibold">
                    {t('pickUpTime').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="time"
                      className="text-xl sm:!text-2xl hover:text-accent-foreground hover:cursor-text focus-visible:text-accent px-0 focus-visible:outline-none focus-visible:ring-0 border-none shadow-none font-semibold text-slate-500 [&::-webkit-calendar-picker-indicator]:hover:cursor-pointer"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="returnTime"
              render={({ field }) => (
                <FormItem className="flex space-y-0 flex-col gap-1 border-b-2 border-slate-100 p-3">
                  <FormLabel className="text-sm text-slate-500 font-semibold">
                    {t('returnTime').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="time"
                      className="text-xl sm:!text-2xl hover:text-accent-foreground hover:cursor-text focus-visible:text-accent px-0 focus-visible:outline-none focus-visible:ring-0 border-none shadow-none font-semibold text-slate-500 [&::-webkit-calendar-picker-indicator]:hover:cursor-pointer"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {isVariant && (
              <LezgoButton isPlaceValid={isPlaceValid} isVariant={true} />
            )}
          </div>

          {!isVariant && (
            <LezgoButton isPlaceValid={isPlaceValid} />
          )}
        </form>
      </Form>
    </div>
  )
}