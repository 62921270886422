// LezgoCalendar.tsx
import { useTranslations } from "next-intl";
import DesktopCalendar from "@/components/booking-form/DesktopCalendar";
import useMediaQuery from "@/lib/hooks/useMediaQuery";
import MobileCalendar from "@/components/booking-form/MobileCalendar";
import { useFormContext } from "react-hook-form";
import { FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useDateRange } from "@/hooks/useDateRange";
import { useEffect } from "react";
import { addDays } from "date-fns";

interface LezgoCalendarProps {
  dateError: string | null;
  nuqsShallow: boolean;
}

export default function LezgoCalendar({ dateError, nuqsShallow }: LezgoCalendarProps) {
  const t = useTranslations('Home.BookingForm');
  const isMobile = useMediaQuery('(max-width: 640px)');
  const { control, setValue } = useFormContext();
  const { dateRange } = useDateRange();
  const now = new Date();
  const threeDaysFromNow = addDays(now, 3);
  const sixDaysFromNow = addDays(now, 6);

  // Synchronize dateRange with form's dates field
  useEffect(() => {
    setValue('dates', dateRange);
    if(!dateRange.from && !dateRange.to) {
      setValue('dates', {
        from: threeDaysFromNow,
        to: sixDaysFromNow
      });
    }
  }, [dateRange, setValue, threeDaysFromNow, sixDaysFromNow]);

  return (
    <div>
      <FormField
        control={control}
        name="dates"
        render={({ field }) => (
          <>
            <FormItem className="flex flex-col gap-1 border-b-2 border-slate-100 space-y-0 p-3 pl-2 pb-2">
              <FormLabel className="text-sm text-slate-500 pl-1 font-semibold">
                {t('when').toUpperCase()}
              </FormLabel>
              {isMobile ? (
                <MobileCalendar nuqsShallow={nuqsShallow} />
              ) : (
                <DesktopCalendar nuqsShallow={nuqsShallow} />
              )}
              {/* Display error message if dateError is present */}
              <FormMessage className="pl-1" />
            </FormItem>
          </>
        )}
      />
    </div>
  );
}
