// DatesDisplay.tsx
'use client'; // Ensure this component is treated as a client component

import { formatDateShort } from "@/utils/dateFormatter";
import { useTranslations, useFormatter } from "next-intl";

interface DateDisplayProps {
  from?: Date | null;
  to?: Date | null;
}

export const DateDisplay: React.FC<DateDisplayProps> = ({ from, to }) => {
  const t = useTranslations('Home.BookingForm');
  const format = useFormatter();

  if (from && to) {
    return (
      <p>
        {t('from')} {formatDateShort(from, format)} - {t('to')} {formatDateShort(to, format)}
      </p>
    );
  } else if (from) {
    return (
      <p>
        {t('from')} {formatDateShort(from, format)} - {t('to')}
      </p>
    );
  } else if (to) {
    return (
      <p>
        {t('from')} - {t('to')} {formatDateShort(to, format)}
      </p>
    );
  } else {
    return <p>{t('pleaseSelectADateRange')}</p>;
  }
};
