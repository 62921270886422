// DesktopCalendar.tsx
import { useEffect } from "react"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { format, addDays, isAfter, isBefore } from "date-fns"
import { DateDisplay } from "@/components/booking-form/DatesDisplay"
import { useQueryState, parseAsIsoDate } from "nuqs"
import { DateRange } from "react-day-picker"
import { useTranslations } from "next-intl"
import { useDateRange } from "@/hooks/useDateRange"

interface DesktopCalendarProps {
  nuqsShallow: boolean
}

export default function DesktopCalendar({nuqsShallow}: DesktopCalendarProps) {
  const t = useTranslations('Home.BookingForm')
  const { dateRange, setDateRange, fromDate, toDate } = useDateRange(nuqsShallow);

  // if( !fromDate && !toDate) {
  //   setFromDate(addDays(new Date(), 3));
  //   setToDate(addDays(new Date(), 6));
  // } 

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"ghost"}
          className={cn(
            "w-full border-none p-0 pl-1 h-[30px] justify-start text-left font-normal text-slate-500 hover:text-accent-foreground hover:bg-transparent",
            !fromDate && "text-muted-foreground"
          )}
        >
          <span className="text-xl sm:text-2xl font-normal">
            <DateDisplay from={fromDate} to={toDate} />
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="range"
          selected={dateRange}
          onSelect={setDateRange}
          numberOfMonths={2}
          disabled={(date) =>
            date <= new Date(new Date().setHours(0, 0, 0, 0))
          }
        />
      </PopoverContent>
    </Popover>
  )
}
