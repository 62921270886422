// hooks/useDateRange.ts
import { useQueryState, parseAsIsoDate } from "nuqs";
import { DateRange } from "react-day-picker";
import { isAfter, isBefore } from "date-fns";

export function useDateRange(shallow: boolean = false) {
  const [fromDate, setFromDate] = useQueryState(
    "pick_up_date",
    parseAsIsoDate.withOptions({ shallow })
  );
  const [toDate, setToDate] = useQueryState(
    "return_date",
    parseAsIsoDate.withOptions({ shallow })
  );

  const dateRange: DateRange = {
    from: fromDate || undefined,
    to: toDate || undefined,
  };

  const setDateRange = (newValue: DateRange | undefined) => {
    if (
      fromDate &&
      toDate &&
      newValue &&
      newValue.to &&
      isAfter(newValue.to, toDate)
    ) {
      setFromDate(newValue.to);
      setToDate(null);
    } else if (
      fromDate &&
      toDate &&
      newValue &&
      newValue.to &&
      newValue.from &&
      isBefore(newValue.from, fromDate)
    ) {
      setFromDate(newValue.from);
      setToDate(null);
    } else if (newValue?.from) {
      setFromDate(newValue.from);
      setToDate(newValue.to || null);
    } else {
      setFromDate(null);
      setToDate(null);
    }
  };

  return { dateRange, setDateRange, fromDate, toDate, setFromDate, setToDate };
}
