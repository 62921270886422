'use client'

import React, { useState } from 'react'
import Image, { ImageProps } from 'next/image'
import fallbackImage from '@/public/placeholder.svg'

interface ImageWithFallbackProps extends Omit<ImageProps, 'src'> {
  src: string
  fallback?: string
}

export default function ImageWithFallback({
  src,
  fallback,
  alt,
  ...props
}: ImageWithFallbackProps) {
  const [error, setError] = useState(false)

  const handleError = () => {
    setError(true)
  }

  return (
    <Image
      alt={alt}
      onError={handleError}
      src={error ? (fallback || fallbackImage) : src}
      {...props}
    />
  )
}