'use client'

import Image from 'next/image'
import { LezgoArrow } from "./booking-form/LezgoArrow"
import { Adventure } from "@/types"
import { Button } from "./ui/button"
import { useRouter } from "@/i18n/routing"

function AdventureCard({ adventure: { title, city, description, image, linkUrl, linkText } }: { adventure: Adventure }) {
  const router = useRouter();

  const handleCityClick = () => {
    router.push(`/car-rental/${city}`)
  }

  return (
    <>
      <hr className="border-black hidden sm:block" />
      <div className="overflow-hidden">
        <div className="flex flex-col items-center justify-center gap-4 sm:flex-row">
          <div className="relative w-full h-[150px] sm:w-[320px]">
            <Image src={image} alt={title} fill className="object-cover" />
          </div>
          <div className="flex-grow w-full"> {/* Added w-full for full width on mobile */}
            <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
              <div>
                <h3 className="text-xl">{title}</h3>
                <p className="text-sm text-muted-foreground mt-1">{description}</p>
              </div>
              <div className="flex w-full sm:w-2/3 items-center justify-between sm:justify-center">
                <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                  <div className="flex items-center gap-3 flex-wrap">
                    <div className="w-[8px] h-[8px] bg-foreground"></div>
                    <div className="flex items-center">
                      <p className="inline-block rounded bg-secondary text-secondary-foreground mr-2 link hover:text-primary">
                        {linkText}
                      </p>
                    </div>
                  </div>
                </a>
                <Button
                  variant="ghost"
                  className="sm:hidden stroke-slate-600 p-2"
                  onClick={handleCityClick}
                >
                  <LezgoArrow className="w-6 h-6" />
                </Button>
              </div>
              <Button
                variant="ghost"
                className="hidden sm:flex sm:items-center stroke-slate-600"
                onClick={handleCityClick}
              >
                <LezgoArrow className="w-8 h-8" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdventureCard